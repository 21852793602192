<template>
        <el-select v-model="item.val" :size="size"  :style="item.style"  :placeholder="item.placeholder" disabled>
            <el-option
                v-for="option in item.selectObj"
                :key="option.id"
                :label="option.label"
                :value="option.value">
            </el-option>
        </el-select>
</template>

<script>
export default {
    props:{
        item:Object,
        size:String
    },
    data(){
        return{

        }
    }
}
</script>

<style>

</style>