<template>
    <el-upload
        disabled
        :action="url"
        :data="data"
        list-type="picture"
        :show-file-list="false"
        :on-success="success"
        :on-error="error"
        :before-upload="createData">
        <i class="el-icon-plus upload" :style="item.style"></i>
    </el-upload>
</template>

<script>
export default {
    props:{
        item:Object,
        size:String,
    },
    data(){
        return{
            url:"https://upload.qiniup.com",
            data:{
                key: "", //图片名字组成  HMPIC-时间戳+4位随机数-文件名
                token: "" //七牛云token
            },
            visitUrl:'https://qiniu.hearming.com'
        }
    },
    methods:{
      success(response, file, fileList){
          this.item.val = fileList
          this.$emit('success',response, file, fileList)
      },
      error(err, file, fileList){
          this.item.val = fileList
          this.$emit('error',err, file, fileList)
      },
      createData(file){
          console.log(file);
        const isPNG = file.type === "image/png";
        const isJPEG = file.type === "image/jpeg";
        const isJPG = file.type === "image/jpg";
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isPNG && !isJPEG && !isJPG) {
            this.$message({
                message:'上传头像图片只能是 JPG 格式!',
                type:'error'
            });
            return false;
        }
        if (!isLt2M) {
            this.$message({
                message:'上传头像图片大小不能超过 2MB!',
                type:'error'
            });
            return false;
        }
        return new Promise((resolve, reject) => {
            this.data.key = `HMJKPIC-${(new Date()).valueOf()}${Math.floor(Math.random()*(9999-1000))+1000}-${file.name}`; // HMPIC-时间戳+4位随机数-文件名
            this.data.token = this.item.qiniuToken
        })

      }
    },
    created(){
    }
}
</script>

<style scoped>
.upload{
    position: relative;
    border: 1px solid #aaa;
}
.upload::before{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
</style>