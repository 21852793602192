import { render, staticRenderFns } from "./createTable.vue?vue&type=template&id=291cfe52&scoped=true&"
import script from "./createTable.vue?vue&type=script&lang=js&"
export * from "./createTable.vue?vue&type=script&lang=js&"
import style0 from "./createTable.vue?vue&type=style&index=0&id=291cfe52&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "291cfe52",
  null
  
)

export default component.exports