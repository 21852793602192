import axios from '../utils/server'

// 未知列表方法
export const getTableColList = (params) => axios.post('/ui/element/queryProp', params);

export const createTableCol = (params) => axios.post('/vue_assemble/api/VueColumn/create', params);

export const createOrEditTable = (params) => axios.post('/table/addOrEditTable', params);

export const queryTableList = (params) => axios.post('/ui/element/queryTable', params);

export const delTable = (params) => axios.post('/table/deleteTable', params);

export const getData = (url, params) => axios.post(url, params);


// 删除"列表的列"
export const DelColumn = (params) => axios.post('/column/deleteColumn', params)