<template>
  <el-form :label-width="labelWidth + 'px'" :label-position="labelPosition"
    :inline="inline === 'true' || inline === true" :style="{ width: formWidth }">
    <dynamicForm @fileUploadSuccess="fileUploadSuccess" @fileUploadError="fileUploadError"
      :size="size" v-for="item in list" :key="item.id" :item="item">
    </dynamicForm>
    <el-form-item>
      <el-button @click="click(btn.click, btn.url)" :type="btn.color"
        v-for="(btn, index) in operations" :key="index" :size="btn.size" :class="btn.class">
        {{ btn.name }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import dynamicForm from "./dynamicForm";

export default {
  data() {
    return {
      columnId: null,
    };
  },
  props: {
    list: Array,
    operations: Array,
    refs: String,
    inline: Boolean / String,
    labelWidth: Number,
    labelPosition: String,
    size: String,
    formWidth: String,
  },
  components: {
    dynamicForm,
  },
  methods: {
    fileUploadSuccess(response, file, fileList) {
      this.$emit("fileUploadSuccess", response, file, fileList);
    },
    fileUploadError(err, file, fileList) {
      this.$emit("fileUploadError", err, file, fileList);
    },
    click(click, url) {
      this.$emit(click, url);
    },
  },
};
</script>

<style scoped>
.operations {
  position: relative;
  display: inline-block;
  margin: 0 10px;
}
.active {
  background-color: #badfecd2;
}
.close {
  position: absolute;
  top: 5px;
  right: 0;
  cursor: pointer;
  display: none;
}
.operations:hover .close {
  display: block;
}
</style>