<template>
        <el-form-item :label="item.label" :class="active ? 'active':''">
            <div class="click"  @click.stop="editFields">
                <string :size="size" v-if="item.type==='string'" :item="item"></string>
                <select-item :size="size" v-if="item.type==='select_item'" :item="item"></select-item>
                <multiple :size="size" v-if="item.type==='multiple'" :item="item"></multiple>
                <integer :size="size" v-if="item.type==='integer'" :item="item"></integer>
                <DatePicker :size="size" v-if="item.type==='datePicker'" :item="item"></DatePicker>
                <uploader @success="success" @error="error" :size="size" v-if="item.type==='uploader'" :item="item"></uploader>
                <i class="el-icon-close close" @click="delJson"></i>
           </div>
       </el-form-item>
</template>

<script>
import multiple from '@/components/formComponents/multiple.vue'
import selectItem from '@/components/formComponents/select_item.vue'
import string from '@/components/formComponents/string.vue'
import integer from '@/components/formComponents/integer.vue'
import uploader from './formComponents/uploader.vue'
import DatePicker from './formComponents/datePicker'

export default {
    props:{
        item:Object,
        size:String,
        active:Boolean
    },
    components:{
        multiple,selectItem,string,integer,uploader,DatePicker
    },
    methods:{
        success(response, file, fileList){
            this.$emit('fileUploadSuccess',response, file, fileList)
        },
        error(err,file,fileList){
            this.$emit('fileUploadError',err, file, fileList)
        },
        delJson(){
            this.$emit("delJson")
        },
        editFields(){
            this.$emit('editFields')
        }
    }
}
</script>

<style scoped>
.el-form-item{
    position: relative;
    border: 1px solid transparent;
    background-color: #add8e62b;
    cursor: move;
}
.active{
    background-color: #badfecd2;
}
.el-form-item:hover{
    border-color:#aaa;
}
.el-form-item:hover .close{
    display: block;
}
.close{
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}
.click{
    height: 100%;
    width: 100%;
    padding-right: 50px;
}
</style>