<template>
  <div>
    <div style="position: relative;">
      <el-table :data="tableColList" v-loading="isloding" stripe highlight-current-row
        @row-click="addColumnJson" @row-contextmenu="rightClick" style="width: 100%">
        <el-table-column prop="systemId" label="系统ID" width="auto">
        </el-table-column>
        <el-table-column prop="label" label="显示名" width="auto">
        </el-table-column>
        <el-table-column prop="name" label="字段名" width="auto">
        </el-table-column>
        <!-- <el-table-column label="操作" width="50px" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click.stop="deleColumn(scope.row.columnId)">删除</el-button>
          </template>
        </el-table-column> -->
      </el-table>

      <!-- 菜单 -->
      <div v-show="menuVisible" class="rightMenu" ref="rightMenu">
        <!-- <el-button type="text" @click="editColumn">编辑</el-button> -->
        <el-button type="text" @click="deleColumn">删除</el-button>
      </div>
    </div>
    <el-pagination background layout="prev, pager, next" :page-size="pageParams.pageSize"
      :current-page.sync="pageParams.pageNumber" @current-change="getTableColList"
      hide-on-single-page :total="total">
    </el-pagination>
    <el-button style="float:right;margin:10px;" size="mini" type="primary" @click="addCol">新增列
    </el-button>
    <el-dialog :title="title" :visible.sync="addTableCol" :close-on-click-modal="false"
      :close-on-press-escape="false" width="600px">
      <createForm @saveTableCol="saveTableCol" @exitTableCol="exitTableCol"
        :operations="formData.operations" :list="formData.fields" :inline="formData.inline"
        :labelWidth="formData.labelWidth" :labelPosition="formData.labelPosition"
        :size="formData.size" :formWidth="formData.formWidth"></createForm>
    </el-dialog>
  </div>
</template>

<script>
import createForm from "@/components/dynamicForm/createForm";
import {
  getTableColList,
  createTableCol,
  getData,
  DelColumn,
} from "@/api/table";
import { getFormData } from "@/api/form";
import _this from "@/utils/bus";

export default {
  data() {
    return {
      tableColList: [],
      addTableCol: false,
      isloding: false,
      title: "",
      total: 10,
      currentRowId: null,
      menuVisible: false,
      pageParams: {
        pageNumber: 1,
        pageSize: 10,
      },
      formData: {},
    };
  },
  components: {
    createForm,
  },
  computed: {
    getFormValue() {
      const obj = {};
      this.formData.fields.forEach((item) => {
        obj[item.name] = item.val;
      });
      return obj;
    },
  },
  mounted() {
    window.addEventListener("click", this.foo);
  },
  methods: {
    // 查询保存的table列表
    getTableColList() {
      this.isloding = true;
      const waitting = setTimeout(() => {
        this.isloding = false;
      }, 500);

      getTableColList(this.pageParams).then((res) => {
        this.tableColList = res.data.data.list;
        this.total = res.data.data.total;
        waitting;
      });
    },
    addCol() {
      console.log(this.formData);
      this.title = "新增列";
      this.addTableCol = true;
    },
    addColumnJson(row) {
      _this.$emit("addColumnJson", row);
      this.$emit("addColumnJson", row);
    },
    saveTableCol(url) {
      // console.log(this.getFormValue);
      // return
      url = "/column/addOrEditColumn";
      getData(url, this.getFormValue).then((res) => {
        this.addTableCol = false;
        this.getTableColList();
      });
    },

    // 右键点击
    rightClick(row, column, event) {
      const { columnId } = row;
      this.currentRowId = columnId;
      event.preventDefault();
      const rightMenu = this.$refs.rightMenu;
      if (event.clientX > 170) {
        rightMenu.style.left = "170px";
      } else {
        rightMenu.style.left = event.clientX + "px";
      }

      if (event.clientY - 150 > 365) {
        rightMenu.style.top = "365px";
      } else {
        rightMenu.style.top = event.clientY - 150 + "px";
      }
      this.menuVisible = true;
    },
    foo() {
      // 取消鼠标监听事件 菜单栏
      this.menuVisible = false;
      document.removeEventListener("click", this.foo); // 关掉监听，
    },
    // 编辑列
    editColumn() {
      this.title = "编辑列";
      this.addTableCol = true;
      this.$bus.$emit("sendColumnId", this.currentRowId);
    },

    // 删除列
    async deleColumn() {
      this.foo();
      const columnId = this.currentRowId;
      const { data: res } = await DelColumn({ columnId });
      if (res.resCode == 1) {
        this.$message({
          message: "列删除成功",
          type: "success",
        });
        this.getTableColList();
      }
    },
    exitTableCol() {
      this.addTableCol = false;
      this.$bus.$emit("resetVal");
    },
  },
  created() {
    this.getTableColList();
    getFormData({ formId: 60 }).then((res) => {
      // this.formData = res.data.data;
      this.formData = res.data.data.list[0];
    });
  },
};
</script>

<style scoped>
.compItem {
  display: inline-block;
  width: 80px;
  height: 80px;
  margin-bottom: 5px;
  border: 1px solid transparent;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  color: rgb(62, 58, 73);
}
.compItem p {
  width: 100%;
  height: 24px;
  line-height: 24px;
  text-align: center;
}
.compItem:hover {
  color: red;
  border-color: rgb(99, 77, 77);
}

.rightMenu {
  width: 108px;
  /* height: 88px; */
  background-color: #fff;
  position: absolute;
  border-radius: 4px;
  box-shadow: 0px 7px 36px 0px rgba(38, 38, 38, 0.11);
}
.rightMenu .el-button {
  width: 100%;
  margin: 0;
}
</style>