<template>
  <div>
    <createTable :title="tableParams.title" :data="tableData" :url="tableParams.url"
      :props="tableParams.props" :tableWidth="tableParams.tableWidth"></createTable>

    <!-- 分页器 -->
    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="1" :page-sizes="[5, 10,15]" :page-size="100"
        layout="total, sizes, prev, pager, next, jumper" :total="dataTotal">
      </el-pagination>
    </div>

  </div>

</template>

<script>
import createTable from "@/components/createTable";
import _this from "@/utils/bus";
import { getData } from "@/api/table";
import axios from "axios";

export default {
  data() {
    return {
      pageSize: 5,
      pageNumber: 1,
      dataTotal: 20,
      tableData: [],
    };
  },
  props: {
    tableParams: Object,
    getData: Boolean,
  },
  components: {
    createTable,
  },
  methods: {
    getTableData() {
      this.tableParams.param.pageSize = this.pageSize;
      this.tableParams.param.pageNumber = this.pageNumber;
      axios
        .post(
          this.tableParams.baseUrl + this.tableParams.url,
          this.tableParams.param
        )
        .then(({ data: res }) => {
          if (res.resCode === 1) {
            if (res.data.length) {
              this.tableData = res.data;
            } else if (res.data.list.length) {
              this.tableData = res.data.list;
              this.dataTotal = res.data.total; //  获取总数
            }
          } else {
            this.$message({
              message: res.resMsg,
              type: "warning",
            });
            this.tableData = [];
          }
          // this.tableData = res.data
        })
        .catch((err) => {
          this.$message({
            message: "接口URL或者baseURL不正确！未成功获取到数据！",
            type: "warning",
          });
          this.tableData = [];
        });
    },
    getColumnJson(json) {
      if (
        !this.tableParams.props.some((item) => item.columnId === json.columnId)
      ) {
        this.tableParams.props.push(json);
      } else {
        // this.$message({
        //     message:'已包含当前点击列',
        //     type:'info'
        // })
      }
    },

    // 分页器
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getTableData();
    },
  },
  watch: {
    getData: {
      handler(to, from) {
        if (to) {
          if (this.tableParams.baseUrl && this.tableParams.url) {
            this.getTableData();
          }
        }
        this.$emit("getDataEnd");
      },
    },
    tableParams: {
      handler(to, from) {
        if (this.tableParams.baseUrl && this.tableParams.url) {
          this.getTableData();
        }
      },
      deep: true,
    },
  },
  created() {
    _this.$on("addColumnJson", this.getColumnJson);
  },
};
</script>

<style>
.pagination {
  margin: 80px auto;
  text-align: center;
}
</style>