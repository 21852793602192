<template>
  <div class="container">
    <el-form ref="searchForm" :model="searchForm">
      <el-row :gutter="20">
        <el-col :span="5">
          <el-form-item>
            <el-input v-model="searchForm.label" placeholder="按名称搜索" class="dafaultInput">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item>
            <el-button type="primary" @click="getclomun"> 搜索</el-button>
          </el-form-item>
        </el-col>
        <el-col :span="4" :offset="12">
          <el-form-item>
            <el-button type="primary" @click="addTableCol=true"> 新建</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-table :data="clomunList" v-loading="loading" stripe highlight-current-row>
      <el-table-column prop="columnId" label="主键ID" min-width="180">
      </el-table-column>
      <el-table-column prop="label" label="显示名" min-width="180">
      </el-table-column>
      <el-table-column prop="name" label="列名称" min-width="180">
      </el-table-column>
      <el-table-column prop="systemId" label="系统id" min-width="180">
      </el-table-column>
      <el-table-column prop="width" label="列宽" min-width="180">
      </el-table-column>
      <el-table-column prop="classname" label="样式名" min-width="180">
      </el-table-column>
      <el-table-column label="操作" fixed="right" min-width="100">
        <template slot-scope="scope">
          <el-button @click.stop="delClomun(scope.row.columnId)" type="text" size="small">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="1" :page-sizes="[5,10, 15]" :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>

    <el-dialog title="新增列" :visible.sync="addTableCol" :append-to-body="true"
      :close-on-click-modal="false" :close-on-press-escape="false" width="600px">
      <createForm @saveTableCol="saveTableCol" :operations="formData.operations"
        :list="formData.fields" :inline="formData.inline" :labelWidth="formData.labelWidth"
        :labelPosition="formData.labelPosition" :size="formData.size"
        :formWidth="formData.formWidth"></createForm>
    </el-dialog>

    <!-- <div class="code">
      <pre></pre>
    </div> -->

  </div>
</template>

<script>
import { getFormData } from "@/api/form";
import { getTableColList, getData, DelColumn } from "@/api/table";
import createForm from "@/components/dynamicForm/createForm";
export default {
  data() {
    return {
      loading: false,
      addTableCol: false, //添加新列对话框的开关
      searchForm: {
        label: "",
      },
      pageNumber: 1,
      pageSize: 10,
      total: null,
      clomunList: [],
      formData: {},
    };
  },
  created() {
    this.getclomun();
    this.getFormData();
  },
  methods: {
    //   加载
    Goloading() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getclomun();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getclomun();
    },
    getFormData() {
      getFormData({ formId: 60 }).then(({ data: res }) => {
        this.formData = res.data.list[0];
      });
    },

    // 获取clomun列表
    async getclomun() {
      this.Goloading();
      const { data: res } = await getTableColList({
        label: this.searchForm.label,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      });
      this.clomunList = res.data.list;
      this.total = res.data.total;
    },
    // 添加新列
    saveTableCol(url) {
      url = "/column/addOrEditColumn";
      getData(url, this.getFormValue).then(({ data: res }) => {
        if (res.resCode == 1) {
          this.$notify.success({
            title: "添加成功",
          });
          this.getclomun();
        } else {
          this.$notify.error({
            title: "添加失败请重试",
          });
        }
        this.addTableCol = false;
      });
    },
    // 删除clomun列
    async delClomun(columnId) {
      const { data: res } = await DelColumn({ columnId });
      if (res.resCode == 1) {
        this.$notify.success({
          title: "删除成功",
        });
        this.getclomun();
      }
    },
  },
  computed: {
    getFormValue() {
      const obj = {};
      this.formData.fields.forEach((item) => {
        obj[item.name] = item.val;
      });
      return obj;
    },
  },
  components: {
    createForm,
  },
};
</script>

<style scoped>
.container {
  width: 1200px;
  min-width: 1000px;
  margin: 0 auto;
}

/* 分页器 */
.el-pagination {
  margin: 40px auto;
  text-align: center;
}

.dafaultInput >>> .el-input__inner {
  width: 214px;
  height: 36px;
  border-radius: 3px;
}

.code {
  height: 300px;
  overflow: auto;
  background-color: pink;
}
</style>