<template>
    <el-input readonly v-model="item.val" :size="size" :placeholder="item.placeholder" :style="item.style"></el-input>
</template>

<script>
export default {
    props:{
        item:Object,
        size:String
    },
    data(){
        return{

        }
    },
    methods:{
    }
}
</script>

<style>

</style>