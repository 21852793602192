<template>
    <el-form :label-width="labelWidth + 'px'" :label-position="labelPosition" :inline="inline === 'true' || inline === true" :style="{'width':formWidth}">
        <draggable v-model="draggable" @update="drag" :animation="200">
            <dynamicForm 
                @editFields="editFields(index)" 
                @fileUploadSuccess="fileUploadSuccess" 
                @fileUploadError="fileUploadError" 
                :size="size" v-for="(item,index) in list" 
                :key="item.id" 
                @delJson="delJson(index)" 
                :item="item"
                :active="viewType === 'fields' && viewIndex === index"
                >
            </dynamicForm>
        </draggable>
        <el-form-item style="background-color: #add8e62b;">
            <div :class="['operations',viewType === 'operations' && viewIndex === index ? 'active':'']" @click="editBtn(index)" v-for="(btn,index) in operations" :key="index">
                <i class="el-icon-close close" @click="delOperation(index)"></i>
                <el-button disabled @click="click(btn.click,btn.url)" :type="btn.color" :size="btn.size" :class="btn.class" >{{btn.name}}</el-button>
            </div>
        </el-form-item>
    </el-form>
</template>

<script>
import dynamicForm from '@/components/dynamicForm'
import draggable from 'vuedraggable'

export default {
    data(){
        return{
            formData:{},
            draggable:this.list,
            currIndex:''
        }
    },
    props:{
        list:Array,
        operations:Array,
        inline:Boolean / String,
        labelWidth:Number,
        labelPosition:String,
        size:String,
        viewIndex:Number,
        viewType:String,
        formWidth:String
    },
    components:{
        dynamicForm,draggable
    },
    methods:{
        fileUploadSuccess(response, file, fileList){
            this.$emit("fileUploadSuccess",response, file, fileList)
        },
        fileUploadError(err,file,fileList){
            this.$emit("fileUploadError",err, file, fileList)
        },
        click(click,url){
            this.$emit(click,url)
        },
        delJson(index){
            this.$emit("delJson",index)
        },
        delOperation(index){
            this.$emit("delOperation",index)
        },
        drag(e){
            this.list.splice(e.newIndex,0,...this.list.splice(e.oldIndex,1))
        },
        editFields(index){
            this.$emit('editFields',index)
        },
        editBtn(index){
            this.$emit('editBtn',index)
        }
    },
    created(){
    }
}
</script>

<style scoped>
.operations{
    position: relative;
    display:inline-block;
    margin: 0 10px;
}
.active{
      background-color: #badfecd2;
}
.close{
    position: absolute;
    top: 5px;
    right: 0;
    cursor: pointer;
    display:none;
}
.operations:hover .close{
    display: block;
}
</style>