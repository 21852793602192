<template>
  <div class="container">
    <el-table ref="singleTable" :data="systemList" v-loading="loading" style="width: 100%">
      <el-table-column property="sid" label="ID" min-width="120">
      </el-table-column>
      <el-table-column property="systemName" label="系统名称" min-width="120">
      </el-table-column>
      <el-table-column property="systemBaseUrl" label="系统访问地址" min-width="120">
      </el-table-column>
      <el-table-column label="当前选中的系统" min-width="120">
        <template slot-scope="scope">
          <el-radio :label="scope.row.sid" v-model="tableRadio" @change="currentSystem">{{""}}
          </el-radio>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="120">
        <template slot-scope="scope">
          <el-button type="text" @click="deleSystem(scope.row.sid)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="1" :page-sizes="[5,10, 15]" :page-size="pageParams.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="pageParams.total">
    </el-pagination>

    <el-divider><i class="el-icon-s-tools"></i></el-divider>

    <!-- 创建系统 -->
    <div class="system">
      <el-form :model="systemForm" :rules="rules" ref="systemForm" label-width="100px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="系统名称" prop="systemName">
              <el-input v-model="systemForm.systemName" placeholder="设置系统名称" clearable
                class="dafaultInput"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="系统编码" prop="systemId">
              <el-input v-model="systemForm.systemId" placeholder="设置系统编号" clearable
                class="dafaultInput"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click="submitForm"> 提交 </el-button>
          </el-col>
        </el-row>

      </el-form>
    </div>
  </div>
</template>

<script>
import {
  POST_QUERYSYSTEM,
  POST_ADDEDITSYSTEM,
  POST_DELESYSTEM,
} from "@/api/form";
export default {
  data() {
    return {
      loading: false,
      pageParams: {
        pageNumber: 1,
        pageSize: 10,
        total: null,
      },

      systemForm: {
        systemName: "",
        systemId: "",
      },

      rules: {
        systemName: [
          { required: true, message: "请输入系统名称", trigger: "blur" },
        ],
        systemId: [
          { required: true, message: "请输入系统编码", trigger: "blur" },
        ],
      },

      systemList: [],
      tableRadio: null,
    };
  },
  created() {
    this.querySystem();
  },
  methods: {
    handleSizeChange(val) {
      this.pageParams.pageSize = val;
      this.querySystem();
    },
    handleCurrentChange(val) {
      this.pageParams.pageNumber = val;
      this.querySystem();
    },

    async querySystem() {
      this.loading = true;
      const waiting = setTimeout(() => {
        this.loading = false;
      }, 500);
      const { data: res } = await POST_QUERYSYSTEM(this.pageParams);
      this.systemList = res.data.list;
      this.pageParams.total = res.data.total;
      this.tableRadio = res.data.list[0].sid;
    },

    // 选中当前的行
    currentSystem() {
      console.log(this.tableRadio);
    },

    // 添加系统
    submitForm() {
      this.$refs.systemForm.validate(async (valid) => {
        if (valid) {
          const { data: res } = await POST_ADDEDITSYSTEM(this.systemForm);
          if (res.resCode == 1) {
            this.$notify.success({
              title: "系统添加成功",
            });
            this.$refs.systemForm.resetFields();
            this.querySystem();
          } else {
            this.$notify.error({
              title: res.resMsg,
            });
          }
        }
      });
    },

    deleSystem(id) {
      this.$confirm("此操作将永久删除该系统, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data: res } = await POST_DELESYSTEM(Array.of(id));
          if (res.resCode) {
            this.$notify.success({
              title: "系统删除成功",
            });
            this.querySystem();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.container {
  width: 1200px;
  min-width: 1000px;
  margin: 0 auto;
}
.system {
  margin: 30px auto;
}

/* 分页器 */
.el-pagination {
  margin: 40px auto;
  text-align: center;
}

/* 输入框 */
.dafaultInput >>> .el-input__inner {
  width: 260px;
  height: 36px;
  border-radius: 3px;
}
</style>