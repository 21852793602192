<template>
        <el-input
         readonly
            type="textarea"
            :rows="5"
            :size="size" 
            :placeholder="item.placeholder"
            v-model="item.val"
             :style="item.style">
        </el-input>
</template>

<script>
export default {
    props:{
        item:Object,
        size:String
    },
    data(){
        return{

        }
    }
}
</script>

<style>

</style>