<template>
  <el-container class="container" style=" min-width: 1200px;">
    <el-header style="border-bottom: 1px solid #aaa">
      <div class="logoImg">
        <!-- <el-image style="width:100%;height:100%;" :src="require('@/assets/img/hemingForm.jpg')" fit="contain"></el-image> -->
        <h1>合鸣科技</h1>
      </div>

      <!-- 顶部菜单栏 -->
      <div class="navMenu">
        <ul>
          <li v-for="(child , index) in navMenu " :key="index">
            <div class="navMenuItem" v-for="(item , index) in child " :key="index">
              <img :src="item.imgSrc">
              <el-button type="text" @click="navMenuClick(item.methods)">{{item.label}}</el-button>
            </div>
            <el-divider direction="vertical"></el-divider>
          </li>
        </ul>
      </div>

      <div>
        <!-- 查看已保存列表 -->
        <el-dialog @closed="closeCheckForm" title="查看已保存表单" :visible.sync="checkForm"
          :close-on-click-modal="false" :close-on-press-escape="false" :width="navDialogWidth">

          <!-- 查看已保存表单列表 -->
          <div v-if="checkformList">
            <el-table :data="formList" stripe highlight-current-row @row-click="selectForm">
              <el-table-column prop="formId" label="表单ID" width="auto" min-width="180">
              </el-table-column>
              <el-table-column prop="formName" label="表单名字" width="auto" min-width="180">
              </el-table-column>
              <el-table-column prop="systemId" label="所属系统" width="auto" min-width="180">
              </el-table-column>
              <el-table-column prop="updateTime" label="最后一次更新时间" width="auto" min-width="180">
              </el-table-column>
              <el-table-column label="操作" fixed="right" width="180">
                <template slot-scope="scope">
                  <el-button @click.stop="openPriver(scope.row,type=1)" type="primary" size="small">
                    预览
                  </el-button>
                  <el-button @click.stop="delForm(scope.row)" type="danger" size="small">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-divider></el-divider>
            <el-pagination background :page-size="pageParams.pageSize"
              :current-page="pageParams.pageNumber" layout="prev, pager, next"
              @current-change="pageTurn" :total="total">
            </el-pagination>
          </div>

          <!-- form全局配置 -->
          <el-form v-if="currentEdit === 'form'" :model="formData" size="small"
            label-position="top">
            <el-form-item label="表单名称" size="mini">
              <el-input v-model="formData.formName" placeholder="请输入表单名称" clearable
                :style="{width: '80%'}"></el-input>
            </el-form-item>
            <el-form-item label="表单所属系统ID">
              <el-input v-model="formData.systemId" placeholder="请输入表单所属系统ID" clearable
                :style="{width: '80%'}">
              </el-input>
            </el-form-item>
            <el-form-item label="表单宽度">
              <el-input v-model="formData.formWidth" placeholder="请输入表单宽度" clearable
                :style="{width: '80%'}">
              </el-input>
            </el-form-item>
            <el-form-item label="是否行内">
              <el-switch v-model="formData.inline"></el-switch>
            </el-form-item>
            <el-form-item label="对齐方式">
              <el-select v-model="formData.labelPosition" placeholder="请选择对齐方式" clearable
                :style="{width: '80%'}">
                <el-option v-for="(item, index) in labelPositionOptions" :key="index"
                  :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="label宽度">
              <el-input v-model.number="formData.labelWidth" placeholder="请输入label宽度" clearable
                :style="{width: '80%'}">
              </el-input>
            </el-form-item>
            <el-form-item label="表单尺寸">
              <el-select v-model="formData.size" placeholder="请选择表单尺寸" clearable
                :style="{width: '81%'}">
                <el-option v-for="(item, index) in sizeOptions" :key="index" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-form>

          <!-- table全局配置 -->
          <el-form v-else-if="currentEdit === 'table'" :model="tableParams" size="mini"
            label-position="top">
            <el-form-item label="系统ID">
              <el-input v-model="tableParams.systemId" placeholder="请输入单行文本系统ID" clearable
                :style="{width: '80%'}">
              </el-input>
            </el-form-item>
            <el-form-item label="表格名称">
              <el-input v-model="tableParams.title" placeholder="请输入单行文本表格名称" clearable
                :style="{width: '80%'}">
              </el-input>
            </el-form-item>
            <el-form-item label="表格数据来源URL">
              <el-input v-model="tableParams.url" clearable :style="{width: '80%'}"></el-input>
            </el-form-item>
            <el-form-item label="表格数据来源URL前缀">
              <el-input v-model="tableParams.baseUrl" placeholder="Base URL" clearable
                :style="{width: '80%'}">
              </el-input>
            </el-form-item>
            <el-form-item label="表格宽度">
              <el-input v-model="tableParams.tableWidth" clearable :style="{width: '80%'}">
              </el-input>
            </el-form-item>
            <el-form-item label="查询参数（JSON字符串）">
              <div v-for="(val,key) in tableParams.param" :key="key">
                {{key}}: <el-input style="width:50%;margin-top:10px;"
                  v-model="tableParams.param[key]"></el-input>
              </div>
              <div v-if="addQueryParams">
                key:
                <el-input style="width:50%;margin-top:10px;" v-model="queryParam.key">
                </el-input><br>
                value:
                <el-input style="width:50%;margin-top:10px;" v-model="queryParam.value">
                </el-input><br>

                <div style="margin-top:8px;">
                  <el-button type="text" icon="el-icon-circle-check" @click="addQueryParamJson">添加
                  </el-button>
                  <el-button type="text" icon="el-icon-circle-close" @click="resetQueryParamJson">
                    取消
                  </el-button>
                </div>

              </div>
              <el-button type="primary" icon="el-icon-plus" style="float:right;margin:10px;" circle
                @click="addQueryParams = true">
              </el-button>
            </el-form-item>

          </el-form>

        </el-dialog>
      </div>

      <el-drawer :title="drawerTitle" class="navDrawer" size="100%" :visible.sync="isMenudrawer"
        direction="rtl" :before-close="closeDrawer">
        <setNewTable v-if="isSetNewTable" :clomunList="clomunList"></setNewTable>
        <setNewClomun v-if="isSetNewClomun" :clomunList="clomunList"></setNewClomun>
        <setSystem v-if="isSetSystem" :clomunList="clomunList"></setSystem>
      </el-drawer>

    </el-header>
    <el-container>
      <el-aside width="280px" style=" border-right: 1px solid #aaa">
        <el-radio-group v-model="currentEdit" style="width:100%" border size="medium ">
          <el-radio-button label="form" style="width:50%"></el-radio-button>
          <el-radio-button label="table" style="width:50%"></el-radio-button>
        </el-radio-group>
        <el-tabs class="asideTabs" v-model="operation" type="" :stretch="true">
          <el-tab-pane :label="currentEdit === 'form' ? '组件':'列'" name="components">
            <div v-if="currentEdit === 'form'">
              <compList @addJson="addJson"></compList>
              <el-button size="mini" type="primary" class="checkForm" @click="createNewForm">创建新表单
              </el-button>
              <el-button size="mini" type="primary" class="checkForm" @click="openCheckForm">查看已保存表单
              </el-button>

            </div>
            <div v-else-if="currentEdit === 'table'">
              <tableColList @addColumnJson="getColumnJson"></tableColList>
              <el-button size="mini" type="primary" class="checkForm" @click="createNewTable">创建新列表
              </el-button>
              <el-button size="mini" type="primary" class="checkForm" @click="openCheckTable">
                查看已保存列表</el-button>
              <el-dialog @closed="closeCheckTable" title="查看已保存列表" :visible.sync="checkTable"
                :close-on-click-modal="false" :close-on-press-escape="false" width="1200px">
                <el-table :data="tableList" stripe highlight-current-row @row-click="selectTable">
                  <el-table-column prop="tableId" label="表格ID" width="auto" min-width="180">
                  </el-table-column>
                  <el-table-column prop="title" label="表格名字" width="auto" min-width="180">
                  </el-table-column>
                  <el-table-column prop="systemId" label="所属系统" width="auto" min-width="180">
                  </el-table-column>
                  <el-table-column prop="updateTime" label="最后一次更新时间" width="auto" min-width="180">
                  </el-table-column>
                  <el-table-column label="操作" fixed="right" width="180">
                    <template slot-scope="scope">
                      <el-button @click.stop="openPriver(scope.row)" type="primary" size="small">预览
                      </el-button>
                      <el-button @click.stop="delTable(scope.row)" type="danger" size="small">删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-divider></el-divider>
                <el-pagination background :page-size="pageParams.pageSize"
                  :current-page="pageParams.pageNumber" layout="prev, pager, next"
                  @current-change="pageTurn" :total="total">
                </el-pagination>
              </el-dialog>
            </div>
          </el-tab-pane>
          <el-tab-pane label="全局配置" name="globalConfig">

            <!-- form全局配置 -->
            <el-form v-if="currentEdit === 'form'" :model="formData" size="small"
              label-position="top">
              <el-form-item label="表单名称" size="mini">
                <el-input v-model="formData.formName" placeholder="请输入表单名称" clearable
                  :style="{width: '80%'}"></el-input>
              </el-form-item>
              <el-form-item label="表单所属系统ID">
                <el-input v-model="formData.systemId" placeholder="请输入表单所属系统ID" clearable
                  :style="{width: '80%'}">
                </el-input>
              </el-form-item>
              <el-form-item label="表单宽度">
                <el-input v-model="formData.formWidth" placeholder="请输入表单宽度" clearable
                  :style="{width: '80%'}">
                </el-input>
              </el-form-item>
              <el-form-item label="是否行内">
                <el-switch v-model="formData.inline"></el-switch>
              </el-form-item>
              <el-form-item label="对齐方式">
                <el-select v-model="formData.labelPosition" placeholder="请选择对齐方式" clearable
                  :style="{width: '80%'}">
                  <el-option v-for="(item, index) in labelPositionOptions" :key="index"
                    :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="label宽度">
                <el-input v-model.number="formData.labelWidth" placeholder="请输入label宽度" clearable
                  :style="{width: '80%'}">
                </el-input>
              </el-form-item>
              <el-form-item label="表单尺寸">
                <el-select v-model="formData.size" placeholder="请选择表单尺寸" clearable
                  :style="{width: '81%'}">
                  <el-option v-for="(item, index) in sizeOptions" :key="index" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-form>

            <!-- table全局配置 -->
            <el-form v-else-if="currentEdit === 'table'" :model="tableParams" size="mini"
              label-position="top">
              <el-form-item label="系统ID">
                <el-input v-model="tableParams.systemId" placeholder="请输入单行文本系统ID" clearable
                  :style="{width: '80%'}">
                </el-input>
              </el-form-item>
              <el-form-item label="表格名称">
                <el-input v-model="tableParams.title" placeholder="请输入单行文本表格名称" clearable
                  :style="{width: '80%'}">
                </el-input>
              </el-form-item>
              <el-form-item label="表格数据来源URL">
                <el-input v-model="tableParams.url" clearable :style="{width: '80%'}"></el-input>
              </el-form-item>
              <el-form-item label="表格数据来源URL前缀">
                <el-input v-model="tableParams.baseUrl" placeholder="Base URL" clearable
                  :style="{width: '80%'}">
                </el-input>
              </el-form-item>
              <el-form-item label="表格宽度">
                <el-input v-model="tableParams.tableWidth" clearable :style="{width: '80%'}">
                </el-input>
              </el-form-item>
              <el-form-item label="查询参数（JSON字符串）">
                <div v-for="(val,key) in tableParams.param" :key="key">
                  {{key}}: <el-input style="width:50%;margin-top:10px;"
                    v-model="tableParams.param[key]"></el-input>
                </div>
                <div v-if="addQueryParams">
                  key:
                  <el-input style="width:50%;margin-top:10px;" v-model="queryParam.key">
                  </el-input><br>
                  value:
                  <el-input style="width:50%;margin-top:10px;" v-model="queryParam.value">
                  </el-input><br>

                  <div style="margin-top:8px;">
                    <el-button type="text" icon="el-icon-circle-check" @click="addQueryParamJson">添加
                    </el-button>
                    <el-button type="text" icon="el-icon-circle-close" @click="resetQueryParamJson">
                      取消
                    </el-button>
                  </div>

                </div>
                <el-button type="primary" icon="el-icon-plus" style="float:right;margin:10px;"
                  circle @click="addQueryParams = true">
                </el-button>
              </el-form-item>

            </el-form>

          </el-tab-pane>
          <!-- <el-tab-pane label="操作" name="json"> </el-tab-pane> -->
        </el-tabs>
      </el-aside>
      <el-container>
        <el-main>
          <div class="view">
            <div v-if="currentEdit === 'form'">
              <h2 :style="{'width':formData.formWidth,'text-align':'center'}">{{formData.formName}}
              </h2>
              <createForm @reset="reset" @query="query" @click="click" @delJson="delJson"
                @editFields="editFields" @editBtn="editBtn" @delOperation="delOperation"
                @fileUploadSuccess="success" @fileUploadError="error"
                :operations="formData.operations" :list="formData.fields" :inline="formData.inline"
                :labelWidth="formData.labelWidth" :labelPosition="formData.labelPosition"
                :size="formData.size" :viewType="viewType" :viewIndex="viewIndex"
                :formWidth="formData.formWidth"></createForm>
              <!-- <createTable></createTable> -->
              <div v-if="formData.fields.length || formData.operations.length"
                class="saveOrResetBtn">
                <el-button size="mini" type="primary" @click="beforeSaveForm">保存</el-button>
                <el-button size="mini" type="info" @click="resetForm">重置</el-button>
              </div>
            </div>
            <div v-else-if="currentEdit === 'table'">
              <tableView :tableParams="tableParams" :getData="getData"
                @getDataEnd="getData = false"></tableView>
              <div v-if="tableParams.props.length || tableParams.operations.length"
                class="saveOrResetBtn">
                <el-button size="mini" type="primary" @click="beforeSaveTable">保存</el-button>
                <el-button size="mini" type="info" @click="resetTable">重置</el-button>
              </div>
            </div>
          </div>
          <div class="config">
            <div v-if="currentEdit === 'form'">

              <el-form v-if="viewType === 'fields' && formData[viewType].length" :model="formData"
                size="mini" label-position="top">
                <el-form-item label="数据库键值">
                  <el-input v-model="formData[viewType][viewIndex].name" clearable
                    :style="{width: '100%'}"></el-input>
                </el-form-item>
                <el-form-item label="标签提示文本">
                  <el-input v-model="formData[viewType][viewIndex].label" clearable
                    :style="{width: '100%'}"></el-input>
                </el-form-item>
                <el-form-item label="占位文本">
                  <el-input v-model="formData[viewType][viewIndex].placeholder" clearable
                    :style="{width: '100%'}">
                  </el-input>
                </el-form-item>

                <el-form-item v-if="formData[viewType][viewIndex].type === 'select_item'"
                  label="下拉列表">
                  <div class="selectOption"
                    v-for="(item,index) in formData[viewType][viewIndex].selectObj" :key="index">
                    <el-input size="mini" v-model="item.value"></el-input>
                    <el-input size="mini" v-model="item.label"></el-input>
                    <el-button type="text" icon="el-icon-remove-outline"
                      @click="deleSelectItem(index)" circle></el-button>
                  </div>

                  <div class="selectOption">
                    <el-input placeholder="实际代表值" size="mini" v-model="selectItem.value">
                    </el-input>
                    <el-input placeholder="要显示的值" size="mini" v-model="selectItem.label">
                    </el-input>
                    <el-button style="opacity: 0;" icon="el-icon-remove-outline" circle>
                    </el-button>
                  </div>

                  <div style="text-align: left;margin: 8px 12px;">
                    <el-button type="text" icon="el-icon-circle-plus-outline" @click="addSelectItem"
                      circle>添加选项</el-button>
                  </div>
                </el-form-item>

                <el-form-item v-if="formData[viewType][viewIndex].type === 'datePicker'"
                  label="日期类型">
                  <el-select @change="handerDateType($event,formData[viewType][viewIndex])"
                    v-model="formData[viewType][viewIndex].datetype" placeholder="请选择"
                    :style="{width: '100%'}">
                    <el-option v-for="(item,index) in dateOptions" :key="index" :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="默认值">
                  <el-input v-model="formData[viewType][viewIndex].val" clearable
                    :style="{width: '100%'}"></el-input>
                </el-form-item>
                <el-form-item label="宽度">
                  <el-input v-model="formData[viewType][viewIndex].style.width" clearable
                    :style="{width: '100%'}"></el-input>
                </el-form-item>
                <el-form-item label="高度">
                  <el-input v-model="formData[viewType][viewIndex].style.height" clearable
                    :style="{width: '100%'}"></el-input>
                </el-form-item>
              </el-form>

              <el-form :model="formData" size="mini"
                v-else-if="viewType === 'operations' && formData[viewType].length"
                label-position="top">
                <el-form-item label="按钮风格">
                  <el-radio-group v-model="formData[viewType][viewIndex].color" size="small">
                    <el-radio-button v-for="(item, index) in colorOptions" :key="index"
                      :label="item.value" :disabled="item.disabled">{{item.label}}</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="按钮大小">
                  <el-radio-group v-model="formData[viewType][viewIndex].size" size="small">
                    <el-radio-button v-for="(item, index) in sizeOptions" :key="index"
                      :label="item.value" :disabled="item.disabled">{{item.label}}</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="按钮名称">
                  <el-input v-model="formData[viewType][viewIndex].name" placeholder="请输入按钮名称"
                    clearable :style="{width: '100%'}"></el-input>
                </el-form-item>
                <el-form-item label="按钮类名">
                  <el-input v-model="formData[viewType][viewIndex].className" placeholder="请输入按钮类名"
                    clearable :style="{width: '100%'}">
                  </el-input>
                </el-form-item>
                <el-form-item label="按钮点击事件">
                  <el-input v-model="formData[viewType][viewIndex].click" placeholder="请输入按钮点击事件"
                    clearable :style="{width: '100%'}"></el-input>
                </el-form-item>
                <el-form-item label="按钮传递URL">
                  <el-input v-model="formData[viewType][viewIndex].url" placeholder="请输入按钮传递URL"
                    clearable :style="{width: '100%'}"></el-input>
                </el-form-item>
              </el-form>

              <div v-else>
                请先选择一个组件
              </div>
            </div>
            <div v-else-if="currentEdit === 'table'">
              <el-form v-if="isColumn" :model="formData" size="mini" label-position="top">
                <el-form-item label="列宽度" prop="width">
                  <el-input v-model="ColumnJson.width" placeholder="请输入列宽度" clearable></el-input>
                </el-form-item>
                <el-form-item label="列绑定类名" prop="classname">
                  <el-input v-model="ColumnJson.classname" placeholder="请输入列绑定类名" clearable>
                  </el-input>
                </el-form-item>
                <el-form-item label="列显示名称" prop="label">
                  <el-input v-model="ColumnJson.label" placeholder="请输入列显示名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="数据库字段名" prop="name">
                  <el-input v-model="ColumnJson.name" placeholder="请输入数据库字段名" clearable></el-input>
                </el-form-item>
                <el-form-item label="系统ID" prop="systemId">
                  <el-input v-model="ColumnJson.systemId" placeholder="请输入系统ID" clearable>
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </el-main>
        <el-footer style="height: 200px; border-top: 1px solid #aaa">
          <pre v-if="currentEdit === 'form'" class="json">{{ formData }}</pre>
          <pre v-else-if="currentEdit === 'table'" class="json">{{ tableParams }}</pre>
        </el-footer>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import createForm from "@/components/createForm";
import tableView from "@/components/tableView";
import setNewTable from "@/components/setNewTable";
import setNewClomun from "@/components/setNewClomun";
import setSystem from "@/components/setSystem";
import compList from "@/components/compList/compList";
import tableColList from "@/components/compList/tableColList";
import {
  getFormData,
  getFormDataNew,
  addOrEditForm,
  findFormDatas,
  update,
  delForm,
} from "@/api/form";
import {
  createOrEditTable,
  queryTableList,
  delTable,
  getTableColList,
} from "@/api/table";

export default {
  data() {
    return {
      inline: false,
      isMenudrawer: false, //抽屉的开关
      isSetNewTable: false, //抽屉打开的是新建table
      isSetNewClomun: false, //抽屉打开的是新建列
      isSetSystem: false, //抽屉打开的是系统设置
      checkformList: false, //查看表单列表
      navDialogWidth: "70%", //顶部菜单对话框宽度
      drawerTitle: "创建表格",
      formData: {
        formName: "",
        systemId: "xls",
        formWidth: "100%",
        inline: "false",
        labelPosition: "left",
        labelWidth: 100,
        size: "mini",
        fields: [],
        operations: [],
      },
      operation: "components",
      viewType: "fields",
      viewIndex: 0,
      selectItem: {
        label: "",
        value: "",
      },
      checkForm: false,
      formList: [],
      pageParams: {
        pageNumber: 1,
        pageSize: 10,
      },
      total: 0,
      currentEdit: "form",
      tableData: {
        title: "表头",
        systemId: "xls",
        url: "/shop",
        prop: [],
        optrations: [],
      },
      addQueryParams: false,
      queryParam: {
        key: "",
        value: "",
      },
      tableParams: {
        systemId: "xls",
        title: "",
        baseUrl: "",
        url: "/vue_assemble/api/query/queryForm",
        param: {},
        props: [],
        operations: [],
        tableWidth: "100%",
      },
      getData: false,
      checkTable: false,
      tableList: [],

      ColumnJson: {}, //当前选中的table列参数
      isColumn: false,

      labelPositionOptions: [
        { label: "左对齐", value: "left" },
        { label: "右对齐", value: "right" },
        { label: "顶部对齐", value: "top" },
      ],
      sizeOptions: [
        { label: "超小", value: "mini" },
        { label: "小", value: "small" },
        { label: "大", value: "medium" },
        { label: "默认", value: "" },
      ],
      colorOptions: [
        {
          label: "默认按钮",
          value: "",
        },
        {
          label: "主要按钮",
          value: "primary",
        },
        {
          label: "成功按钮",
          value: "success",
        },
        {
          label: "信息按钮",
          value: "info",
        },
        {
          label: "警告按钮",
          value: "warning",
        },
        {
          label: "危险按钮",
          value: "danger",
        },
      ],
      dateOptions: [
        { label: "默认", value: "date" },
        { label: "周", value: "week" },
        { label: "月", value: "month" },
        { label: "年", value: "year" },
        { label: "多个日期", value: "dates" },
        { label: "时间范围", value: "daterange" },
        { label: "月份范围", value: "monthrange" },
        { label: "日期和时间点", value: "datetime" },
        { label: "日期和时间范围", value: "datetimerange" },
      ],

      navMenu: [
        [
          {
            label: "表单",
            imgSrc:
              "https://qiniu.hearming.com/OA-16227709170981689-dynamicIcon-01.png",
          },
          {
            label: "查看",
            imgSrc:
              "https://qiniu.hearming.com/OA-16227711986367552-dynamicIcon-02.png",
            methods: "checkformList",
          },
          {
            label: "设置",
            imgSrc:
              "https://qiniu.hearming.com/OA-16227712398337302-dynamicIcon-03.png",
            methods: "setGlobalForm",
          },
        ],
        [
          {
            label: "表格",
            imgSrc:
              "https://qiniu.hearming.com/OA-16227881817694054-dynamicIcon-04.png",
            methods: "setNewTable",
          },
          {
            label: "设置",
            imgSrc:
              "https://qiniu.hearming.com/OA-16227712398337302-dynamicIcon-03.png",
            methods: "setGlobalTable",
          },
        ],
        [
          {
            label: "字段",
            imgSrc:
              "https://qiniu.hearming.com/OA-16227881118972882-dynamicIcon-05.png",
            methods: "setNewClomun",
          },
          {
            label: "设置",
            imgSrc:
              "https://qiniu.hearming.com/OA-16227712398337302-dynamicIcon-03.png",
          },
        ],
        [
          {
            label: "动作",
            imgSrc:
              "https://qiniu.hearming.com/OA-16227882110696893-dynamicIcon-06.png",
          },
          {
            label: "设置",
            imgSrc:
              "https://qiniu.hearming.com/OA-16227712398337302-dynamicIcon-03.png",
          },
        ],
        [
          {
            label: "系统",
            imgSrc:
              "https://qiniu.hearming.com/OA-16227882257183401-dynamicIcon-07.png",
            methods: "setSystem",
          },
        ],
      ],

      clomunList: [],
    };
  },
  components: {
    createForm,
    tableView,
    compList,
    tableColList,
    setNewTable,
    setNewClomun,
    setSystem,
  },
  methods: {
    success(response, file, fileList) {
      console.log(response, file, fileList);
    },
    error(err, file, fileList) {
      console.log(err, file, fileList);
    },
    query(url) {
      console.log(url);
    },
    click(url) {
      console.log(url);
    },
    reset(url) {
      console.log(url);
    },
    addJson(type, json) {
      if (type === "button") {
        this.formData.operations.push(json);
        this.viewType = "operations";
        this.viewIndex = this.formData.operations.length - 1;
      } else {
        this.formData.fields.push(json);
        this.viewType = "fields";
        this.viewIndex = this.formData.fields.length - 1;
      }
    },
    delJson(index) {
      this.formData.fields.splice(index, 1);
    },
    delOperation(index) {
      this.formData.operations.splice(index, 1);
    },
    editFields(index) {
      this.viewType = "fields";
      this.viewIndex = index;
    },
    editBtn(index) {
      this.viewType = "operations";
      this.viewIndex = index;
    },
    addSelectItem() {
      if (this.selectItem.value && this.selectItem.label) {
        this.formData[this.viewType][this.viewIndex].selectObj.push({
          value: this.selectItem.value,
          label: this.selectItem.label,
        });
        this.selectItem.value = "";
        this.selectItem.label = "";
      } else {
        this.$message({
          message: "请输入键值对儿",
          type: "warning",
        });
      }
    },
    deleSelectItem(index) {
      this.formData[this.viewType][this.viewIndex].selectObj.splice(index, 1);
    },

    // 顶部菜单栏点击
    navMenuClick(methods) {
      // console.log(methods);
      if (methods == "checkformList") {
        this.checkformList = true;
        this.currentEdit = "";
        this.openCheckForm();
      } else if (methods == "setGlobalForm") {
        this.checkForm = true;
        this.currentEdit = "form";
        this.navDialogWidth = "30%";
      } else if (methods == "setNewTable") {
        getTableColList({})
          .then(({ data: res }) => {
            this.clomunList = res.data.list;
            this.isSetNewTable = true;
            this.isMenudrawer = true;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (methods == "setGlobalTable") {
        this.checkForm = true;
        this.currentEdit = "table";
        this.navDialogWidth = "30%";
      } else if (methods == "setNewClomun") {
        this.drawerTitle = "Clomun管理";
        this.isSetNewClomun = true;
        this.isMenudrawer = true;
      } else if (methods == "setSystem") {
        this.drawerTitle = "系统设置";
        this.isSetSystem = true;
        this.isMenudrawer = true;
      }
    },

    closeDrawer() {
      this.isMenudrawer = false;
      this.isSetNewClomun = false;
      this.isSetNewTable = false;
      this.isSetSystem = false;
    },

    // 创建表单前校验
    beforeSaveForm() {
      if (!this.formData.formName) {
        this.$prompt("请输入表单名", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputValidator: (value) => {
            if (!value) return "表单名不能为空";
          },
        })
          .then(({ value }) => {
            this.formData.formName = value;
            this.saveForm();
          })
          .catch(() => {});
      } else {
        this.saveForm();
      }
    },

    // 发送创建表单请求
    saveForm() {
      addOrEditForm(this.formData).then((res) => {
        console.log(res);
        this.$message({
          message: `保存成功,formId为${res.data.data.formId}`,
          type: "success",
        });
        this.createNewForm();
      });
    },
    resetForm() {
      this.formData.fields = [];
      this.formData.operations = [];
      this.viewType = "fields";
      this.viewIndex = 0;
    },
    // 打开form表单列表
    openCheckForm() {
      this.checkForm = true;
      findFormDatas(this.pageParams).then((res) => {
        this.formList = res.data.data.list;
        this.total = res.data.data.total;
      });
    },

    // form表单翻页操作
    pageTurn(page) {
      this.pageParams.pageNumber = page;
      if (this.checkForm) {
        findFormDatas(this.pageParams).then((res) => {
          this.formList = res.data.data.list;
          this.total = res.data.data.total;
        });
      } else {
        queryTableList(this.pageParams).then((res) => {
          this.tableList = res.data.data.list;
          this.total = res.data.data.total;
        });
      }
    },

    // 关闭表单对话框重置分页
    closeCheckForm() {
      this.currentEdit = "form";
      this.checkformList = false;
      this.navDialogWidth = "70%";
      this.pageParams.pageNumber = 1;
    },
    // 删除表单
    delForm({ formId }) {
      delForm(this.$qs.stringify({ id: formId })).then((res) => {
        findFormDatas(this.pageParams).then((res) => {
          this.$message({
            message: "删除成功！",
            type: "success",
          });
          this.formList = res.data.data.list;
          this.total = res.data.data.total;
        });
      });
    },

    // 选中列表中的form表单
    selectForm(row) {
      this.viewType = "filed";
      this.viewIndex = 0;
      this.formData = row;
      this.checkForm = false;
    },
    changeInline(inline) {
      inline
        ? (this.formData.inline = "true")
        : (this.formData.inline = "false");
    },

    // 创建表格前校验
    beforeSaveTable() {
      if (!this.formData.formName) {
        this.$prompt("请输入表格名", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputValidator: (value) => {
            if (!value) return "表格名不能为空";
          },
        })
          .then(({ value }) => {
            this.tableParams.title = value;
            this.saveTable();
          })
          .catch(() => {});
      } else {
        this.saveTable();
      }
    },

    //创建表格
    saveTable() {
      createOrEditTable(this.tableParams).then((res) => {
        this.getData = true;
        console.log(res);
        this.$message({
          message: "保存成功！表格ID为：" + res.data.data.tableId,
          type: "success",
        });
        // this.tableParams = res.data.data;
        this.createNewTable();
      });
    },

    resetTable() {
      this.getData = true;
      // this.tableParams.baseUrl= ''
      // this.tableParams.url= ''
      this.tableParams.props = [];
      this.tableParams.operations = [];
    },
    addQueryParamJson() {
      if (this.queryParam.key && this.queryParam.value) {
        this.tableParams.param[this.queryParam.key] = this.queryParam.value;
        // this.$set(this.tableParams.param,this.queryParam.key,this.queryParam.value)
        this.resetQueryParamJson();
      } else {
        this.$message({
          message: "请输入key和value",
          type: "info",
        });
      }
    },
    resetQueryParamJson() {
      this.addQueryParams = false;
      this.queryParam = {
        key: "",
        value: "",
      };
    },
    openCheckTable() {
      this.checkTable = true;
      queryTableList(this.pageParams).then((res) => {
        this.tableList = res.data.data.list;
        this.total = res.data.data.total;
      });
    },
    closeCheckTable() {
      this.pageParams.pageNumber = 1;
    },
    selectTable(row) {
      this.tableParams = row;
      if (!this.tableParams.param) {
        this.tableParams.param = {};
      }
      this.checkTable = false;
    },
    getColumnJson(row) {
      this.isColumn = true;
      this.ColumnJson = row;
    },
    delTable({ tableId }) {
      delTable(this.$qs.stringify({ id: tableId })).then((res) => {
        queryTableList(this.pageParams).then((res) => {
          console.log(res);
          this.$message({
            message: "删除成功！",
            type: "success",
          });
          this.tableList = res.data.data.list;
          this.total = res.data.data.total;
        });
      });
    },
    createNewForm() {
      this.formData = {
        formName: "",
        systemId: "xls",
        formWidth: "100%",
        inline: "false",
        labelPosition: "left",
        labelWidth: 100,
        size: "mini",
        fields: [],
        operations: [],
      };
    },
    createNewTable() {
      this.tableParams = {
        systemId: "xls",
        title: "",
        baseUrl: "",
        url: "/vue_assemble/api/query/queryForm",
        param: {},
        props: [],
        operations: [],
        tableWidth: "100%",
      };
    },

    // 打开预览
    openPriver(date, type) {
      this.$router.push({
        name: "newPreview",
        params: { date, type },
      });
    },

    // 日期类型选择变化
    handerDateType(val, date) {
      if (val == "week") {
        date.format = "yyyy 第 WW 周";
      } else if (val == "month" || val == "monthrange") {
        date.format = "yyyy 年 MM 月";
        date.valFormat = "yyyy-MM";
      } else if (val == "year") {
        datetime;
        date.format = "yyyy 年";
        date.valFormat = "yyyy";
      } else if (val == "datetime" || val == "datetimerange") {
        date.format = "";
        date.valFormat = "yyyy-MM-dd-HH-mm-ss";
      }
    },
  },
  created() {
    // this.getFormData();
  },
};
</script>

<style scoped>
.el-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #aaa;
}
.logoImg {
  width: 260px;
  height: 100%;
}
.logoImg h1 {
  text-align: center;
  line-height: 60px;
}

.navMenu {
  width: calc(100% - 540px);
}
.navMenu ul {
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
}
.navMenu li {
  display: flex;
  align-items: center;
}
.navMenu img {
  width: 24px;
  height: 24px;
}
.navMenuItem {
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.navMenu .el-divider {
  height: 20px;
  margin: 0 50px;
  background: #e6e6e6;
}

@media screen and (max-width: 1500px) {
  .navMenu .el-divider {
    margin: 0 30px;
  }
}

.navMenuItem .el-button {
  margin-top: 2px;
  padding: 0;
}

/* 让抽屉高度自适应 */
.navDrawer >>> .el-drawer__body {
  overflow: auto;
}
/* 隐藏滚动条 */
.navDrawer >>> .el-drawer__container ::-webkit-scrollbar {
  display: none;
}

/* .el-header{
    padding: 0;
} */
.el-aside {
  height: calc(100vh - 60px);
  overflow-y: scroll;
}
.el-aside::-webkit-scrollbar {
  display: none;
}
.el-main {
  height: calc(100vh - 260px);
  padding: 0;
}
.json {
  height: 100%;
  overflow: auto;
  font-size: 12px;
}
.json::-webkit-scrollbar {
  display: none;
}
.view {
  width: calc(100% - 300px);
  height: 100%;
  border-right: 1px solid #aaa;
  padding: 20px;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
}
.config {
  width: 300px;
  height: 100%;
  overflow: auto;
  display: inline-block;
  vertical-align: top;
  padding: 20px;
  box-sizing: border-box;
}
.addSelectItem {
  cursor: pointer;
  margin: 10px 0;
}
.saveOrResetBtn {
  margin-top: 20px;
  margin-right: 20px;
  float: right;
}
.option {
  float: right;
  height: 100%;
}
.checkForm {
  margin: 10px;
}
/* .checkForm >>> .el-form-item{
  margin-bottom: 20px !important;
} */
.el-form-item {
  text-align: center;
  margin-bottom: 5px !important;
}
/* 下拉框的选项 */
.selectOption {
  display: flex;
  margin-bottom: 8px;
}
</style>

<style >
.el-radio-button__inner {
  width: 100%;
}

/* .asideTabs {
  background: #252626 !important;
  border: none !important;
}
.el-tabs--border-card > .el-tabs__header {
  border-bottom: none !important;
  background: none !important;
}
.el-tabs__item.is-active {
  background: #505151 !important;
}
.asideTabs .el-tabs__content {
  padding: 0 !important;
} */
</style>