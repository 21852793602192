<template>

  <el-date-picker readonly  v-model="item.val" :type="item.datetype" :format="item.format"
    :value-format="item.valFormat" :placeholder="item.placeholder" :style="item.style">
  </el-date-picker>

</template>

<script>
export default {
  props: {
    item: Object,
    size: String,
  },
  data() {
    return {};
  },
};
</script>

<style>
</style>