<template>
  <div>
    <div class="compItem" v-for="(item, index) in compList" :key="index"
      @click="addJson(item.type)">
      <img :src="item.iconUrl" alt="icon">
      <p>{{ item.name }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      compList: [
        {
          name: "文本",
          type: "string",
          iconUrl: "https://qiniu.hearming.com/fromIcon-01.png",
        },
        {
          name: "数字",
          type: "integer",
          iconUrl: "https://qiniu.hearming.com/fromIcon-02.png",
        },
        {
          name: "选择框",
          type: "select_item",
          iconUrl: "https://qiniu.hearming.com/fromIcon-03.png",
        },
        {
          name: "上传",
          type: "uploader",
          iconUrl: "https://qiniu.hearming.com/fromIcon-04.png",
        },
        {
          name: "文本域",
          type: "multiple",
          iconUrl: "https://qiniu.hearming.com/fromIcon-05.png",
        },
        {
          name: "按钮",
          type: "button",
          iconUrl: "https://qiniu.hearming.com/fromIcon-06.png",
        },
        {
          name: "日期",
          type: "datePicker",
          iconUrl: "https://qiniu.hearming.com/fromIcon-07.png",
        },
        // {
        //   name: "时间",
        //   type: "times",
        //   iconUrl: "https://qiniu.hearming.com/fromIcon-08.png",
        // },
      ],
    };
  },
  methods: {
    addJson(type) {
      var json = {};
      if (type === "uploader") {
        json = {
          name: "",
          type,
          label: "提示文本",
          placeholder: "上传",
          selectObj: [],
          val: null,
          style: { width: "200px", height: "200px" },
        };
      } else if (type === "multiple") {
        json = {
          name: "",
          type,
          label: "提示文本",
          placeholder: "文本域",
          selectObj: [],
          val: null,
          style: { width: "200px", height: "100px" },
        };
      } else if (type === "string") {
        json = {
          name: "",
          type,
          label: "提示文本",
          placeholder: "普通文本",
          selectObj: [],
          val: null,
          style: { width: "200px" },
        };
      } else if (type === "integer") {
        json = {
          name: "",
          type,
          label: "提示文本",
          placeholder: "数字输入",
          selectObj: [],
          val: null,
          style: { width: "200px" },
        };
      } else if (type === "select_item") {
        json = {
          name: "",
          type,
          label: "提示文本",
          placeholder: "下拉框",
          selectObj: [],
          val: null,
          style: { width: "200px" },
        };
      } else if (type === "button") {
        json = {
          color: "primary",
          size: "mini",
          name: "按钮",
          className: "button",
          click: "click",
          url: "/",
        };
      } else if (type === "datePicker") {
        json = {
          name: "",
          type,
          datetype: "date",   //日期类型
          format: "yyyy 年 MM 月 dd 日",   //日期类型
          valFormat: "yyyy-MM-dd",   //指定绑定值的格式
          label: "日期",
          placeholder: "请选择",
          val: null,
          style: { width: "200px" },
        };
      }
      this.$emit("addJson", type, json);
    },
  },
};
</script>

<style scoped>
.compItem {
  display: inline-block;
  width: 80px;
  height: 80px;
  cursor: pointer;
  position: relative;
  background: #363738;
  border-radius: 8px;
  text-align: center;
  margin: 15px 6px 0px;
}
.compItem img {
  width: 36px;
  margin: 6px 0 4px;
}
.compItem p {
  font-size: 13px;
  color: #999999;
}
.compItem:hover {
  color: red;
  border-color: rgb(99, 77, 77);
}
</style>