import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
}, {
    path: '/preview',
    name: 'preview',
    component: () =>
        import ('@/views/Preview')
}, {
    path: '/newPreview',
    name: 'newPreview',
    component: () =>
        import ('@/views/newPreview')
}, {
    path: '/user',
    component: () =>
        import ('@/demo/user')
}]

const router = new VueRouter({
    routes
})

export default router