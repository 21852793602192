<template>
  <el-input v-model="item.val" :size="size" :placeholder="item.placeholder" :style="item.style">
  </el-input>
</template>

<script>
export default {
  props: {
    item: Object,
    size: String,
  },
  data() {
    return {
      value: null,
    };
  },
  mounted() {
    this.value = this.item.val;
    // this.$bus.$on("resetVal", this.resetVal);
  },
  methods: {
    resetVal() {
      this.value = "";
    },
  },
};
</script>

<style>
</style>