<template>
  <el-form-item :label="item.label">
    <string :size="size" v-if="item.type==='string'" :item="item"></string>
    <select-item :size="size" v-if="item.type==='select_item'" :item="item"></select-item>
    <multiple :size="size" v-if="item.type==='multiple'" :item="item"></multiple>
    <integer :size="size" v-if="item.type==='integer'" :item="item"></integer>
    <DatePicker :size="size" v-if="item.type==='datePicker'" :item="item"></DatePicker>
    <uploader @success="success" @error="error" :size="size" v-if="item.type==='uploader'"
      :item="item"></uploader>
  </el-form-item>
</template>

<script>
import multiple from "./formComp/multiple";
import selectItem from "./formComp/select_item";
import string from "./formComp/string";
import integer from "./formComp/integer";
import uploader from "./formComp/uploader";
import DatePicker from "./formComp/datePicker";

import { getTableColList } from "../../api/table";

export default {
  props: {
    item: Object,
    size: String,
  },
  data() {
    return {
      columnId: null,
    };
  },
  components: {
    multiple,
    selectItem,
    string,
    integer,
    uploader,
    DatePicker,
  },
  mounted() {
    this.$bus.$on("sendColumnId", (columnId) => {
      this.columnId = columnId;
    });
  },
  methods: {
    success(response, file, fileList) {
      this.$emit("fileUploadSuccess", response, file, fileList);
    },
    error(err, file, fileList) {
      this.$emit("fileUploadError", err, file, fileList);
    },
    async getTable() {
      const { data: res } = await getTableColList({
        columnId: this.columnId,
      });
      console.log(res.data.list[0]);
    },
  },
};
</script>

<style scoped>
</style>