<template>
  <div class="container">
    <h2 class="title">{{title}}</h2>
    <el-table :data="data" stripe highlight-current-row :header-row-style="{'font-weight':'800'}"
      :style="{'width':tableWidth}">
      <el-table-column v-for="item in props" :key="item.columnId" :prop="item.name"
        :label="item.label" :width="item.width" :class-name="item.classname">
      </el-table-column>
      <el-table-column v-if="optrations.length" label="操作" width="200" fixed="right">
        <template v-for="(item,index) in optrations">
          <el-button v-if="item.type === 'button'" :key="index" :size="item.size" :type="item.color"
            :class="item.class">{{item.name}}</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>

</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "表名",
    },
    data: Array,
    url: String,
    props: Array,
    optrations: {
      type: Array,
      default: () => [],
    },
    tableWidth: String,
  },
};
</script>

<style scoped>
.title {
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
}
</style>