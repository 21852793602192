<template>
    <el-input readonly v-model="item.val" :size="size" :placeholder="item.placeholder" oninput="value=value.replace(/[^0-9.]/g,'')" :style="item.style"></el-input>
</template>

<script>
export default {
    props:{
        item:Object,
        size:String,
    },
    data(){
        return{
            
        }
    }
}
</script>

<style>

</style>