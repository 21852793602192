<template>
  <div class="container">

    <div class="tableListBox">
      <el-table :data="tableList" v-loading="loading" stripe highlight-current-row
        @row-click="selectTable">
        <el-table-column prop="tableId" label="表格ID" min-width="180">
        </el-table-column>
        <el-table-column prop="title" label="表格名字" min-width="180">
        </el-table-column>
        <el-table-column prop="systemId" label="所属系统" min-width="180">
        </el-table-column>
        <el-table-column prop="updateTime" label="最后一次更新时间" min-width="180">
        </el-table-column>
        <el-table-column label="操作" fixed="right" min-width="180">
          <template slot-scope="scope">
            <el-button @click.stop="openPriver(scope.row)" type="primary" size="small">预览
            </el-button>
            <el-button @click.stop="delTable(scope.row)" type="danger" size="small">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="1" :page-sizes="[5,10, 15]" :page-size="pageParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="pageParams.total">
      </el-pagination>

      <el-row>
        <el-col :offset="20">
          <el-button type="primary" @click="setTable=!setTable">{{ setTable ? '收起' : "创建"}}
          </el-button>
        </el-col>
      </el-row>

      <el-divider><i class="el-icon-s-tools"></i></el-divider>

      <el-row :gutter="20" v-show="setTable">

        <p class="itemTilte">定义表格名称</p>
        <el-form ref="tableForm" :model="formData" :rules="rules" label-width="100px">
          <el-row>
            <el-col :span="6">
              <el-form-item label="表格名称" prop="title">
                <el-input v-model="formData.title" placeholder="请输入表格名称" clearable
                  class="dafaultInput">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :offset="1" :span="6">
              <el-form-item label="选择系统" prop="systemId">
                <el-select v-model="formData.systemId" placeholder="选择系统" clearable
                  class="dafaultInput">
                  <el-option v-for="(item, index) in systemIdOptions" :key="index"
                    :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="请求地址" prop="url">
                <el-input v-model="formData.url" placeholder="表格数据来源URL" clearable
                  class="dafaultInput">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :offset="1" :span="6">
              <el-form-item label="BaseUrl" prop="baseUrl">
                <el-input v-model="formData.baseUrl" placeholder="表格数据来源URL前缀" clearable
                  class="dafaultInput">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <p class="itemTilte">选择表格栏目</p>
        <template>
          <el-transfer ref="transfer" filterable :titles="['项目列表', '我的项目']"
            :button-texts="['退出', '加入']" filter-placeholder="搜索项目" v-model="transferValue"
            :data="data" @change="addclomun">
            <span slot-scope="{ option }">{{ option.columnId }} - {{ option.label }} -
              {{option.systemId}}</span>
          </el-transfer>
        </template>

         <p class="itemTilte">操作</p>

        <div class="code">
          <pre>{{formData}}</pre>
        </div>

        <el-col :span="24" style="margin-top: 50px;">
          <el-button type="primary" @click="submitForm">保存</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-col>

      </el-row>

    </div>

    <div style="height: 500px;"></div>

  </div>
</template>

<script>
import { queryTableList, createOrEditTable, delTable } from "@/api/table";
export default {
  props: ["clomunList"],
  data() {
    return {
      loading: false,
      setTable: false,
      pageParams: {
        pageNumber: 1,
        pageSize: 10,
        total: null,
      },

      tableList: [],
      // clomunList: [],

      formData: {
        systemId: "xls",
        title: "", //表单名称
        baseUrl: "",
        url: "/vue_assemble/api/query/queryForm",
        param: {},
        props: [],
        operations: [],
        tableWidth: "100%",
      },
      rules: {
        title: [{ required: true, message: "请输入表格名称", trigger: "blur" }],
        systemId: [{ required: true, message: "选择系统", trigger: "change" }],
        url: [{ required: true, message: "选输入请求地址", trigger: "blur" }],
        baseUrl: [
          {
            required: true,
            message: "请输入表格数据来源URL前缀",
            trigger: "blur",
          },
        ],
      },
      systemIdOptions: [
        {
          label: "选项一",
          value: 1,
        },
        {
          label: "选项二",
          value: 2,
        },
      ],

      data: [], //数据初始化穿梭器里面的值
      transferValue: [], //穿梭器右侧的值
    };
  },
  created() {
    this.getTableList();
    this.data = this.generateData();
  },
  methods: {
    handleSizeChange(val) {
      this.pageParams.pageSize = val;
      this.getTableList();
    },
    handleCurrentChange(val) {
      this.pageParams.pageNumber = val;
      this.getTableList();
    },
    //   获取已保存列表
    async getTableList() {
      this.loading = true;
      const waiting = setTimeout(() => {
        this.loading = false;
      }, 500);
      try {
        const { data: res } = await queryTableList(this.pageParams);
        this.tableList = res.data.list;
        this.pageParams.total = res.data.total;
      } catch (err) {
        console.log(err);
      }
      waiting;
    },

    selectTable() {},
    // 预览
    openPriver(date, type) {
      this.$router.push({
        name: "newPreview",
        params: { date, type },
      });
    },
    // 删除
    delTable({ tableId, systemId }) {
      delTable(this.$qs.stringify({ tableId: tableId, systemId })).then(
        ({ data: res }) => {
          if (res.resCode == 1) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
          }
          this.getTableList();
        }
      );
    },

    generateData() {
      const data = [];
      this.clomunList.forEach((item, index) => {
        data.push({
          classname: item.classname,
          columnId: item.columnId,
          deleteFlag: item.deleteFlag,
          label: item.label,
          name: item.name,
          systemId: item.systemId,
          width: item.width,

          key: index,
        });
      });
      return data;
    },

    // 穿梭器改变
    addclomun() {
      let dataArray = [];
      this.formData.props = [];
      this.transferValue.map((item) => {
        dataArray.push(item);
      });
      dataArray.map((item) => {
        this.formData.props.push(this.clomunList[item]);
      });
    },

    // 提交
    submitForm() {
      this.$refs["tableForm"].validate(async (valid) => {
        if (!valid) return;
        if (this.formData.props.length) {
          const { data: res } = await createOrEditTable(this.formData);
          console.log(res.data);
        }
      });
    },
    // 重置
    resetForm() {
      this.$refs["tableForm"].resetFields();
    },
  },
};
</script>

<style scoped>
.container {
  width: 95%;
  margin: 0 auto;
}

/* table列表容器 */

.tableListBox {
  width: 1200px;
  min-width: 1000px;
  margin: 0 auto;
}

/* 分页器 */
.el-pagination {
  margin: 40px auto;
  text-align: center;
}

/* 标题 */
.itemTilte {
  font-size: 16px;
  margin: 30px 20px;
  color: #252626;
}

/* 代码 */
.code {
  height: 350px;
  overflow: auto;
  padding: 20px;
  margin: 20px 0;
  background-color: pink;
}

.dafaultInput >>> .el-input__inner {
  width: 214px;
  height: 36px;
  border-radius: 3px;
}

.el-transfer >>> .el-transfer-panel {
  width: 280px;
}
</style>