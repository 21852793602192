import axios from '../utils/server'

export const getFormData = (params) => axios.post('/ui/element/queryForm', params);

export const addOrEditForm = (params) => axios.post('/from/addOrEditForm', params);

export const findFormDatas = (params) => axios.post('/ui/element/queryForm', params);

export const delForm = (params) => axios.post('/from/delete', params);



// 系统设置
// 分页查询 系统 记录
export const POST_QUERYSYSTEM = (params) => axios.post('/system/querySystemPage', params);
// 新增或者编辑 系统 记录
export const POST_ADDEDITSYSTEM = (params) => axios.post('/system/addEditSystem', params);
// 根据主键查找 系统 记录
export const POST_FINDSYSTEM = (params) => axios.post('/system/findSystem', params);
// 批量删除 系统 记录, 返回删除的主键
export const POST_DELESYSTEM = (params) => axios.post('/system/deleteByIdList', params);